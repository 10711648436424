<template>
  <div
    style="
      box-shadow:
        rgba(102, 75, 0, 0) 0px 966px 270px 0px,
        rgba(102, 75, 0, 0.02) 0px 618px 247px 0px,
        rgba(102, 75, 0, 0.08) 0px 348px 209px 0px,
        rgba(102, 75, 0, 0.14) 0px 155px 155px 0px,
        rgba(102, 75, 0, 0.16) 0px 39px 85px 0px,
        rgba(0, 0, 0, 0.25) 0px 4px 50px 0px;
    "
  >
    <div class="-mb-[1px] flex items-center gap-2.5 p-5 text-xl md:hidden">
      <img class="aspect-square min-w-[1em] filter" src="/icons/wallet.svg" alt="" />
      <span class="larken text-white">Wallet</span>
      <button
        class="ml-auto flex cursor-pointer items-center justify-center rounded-lg bg-white/5 p-1.5 text-xs text-white/50"
        @click="handleClose"
      >
        <Icon icon="humbleicons:times" />
      </button>
    </div>
    <ConnectedWallet
      v-for="(wallet, index) in wallets"
      :key="wallet.type"
      :wallet="wallet"
      :class="index > 0 ? 'border-t border-solid border-[#282828]' : ''"
      @collapse="emitClose"
    />
    <a
      class="larken -m-[1px] flex cursor-pointer items-center gap-4 whitespace-nowrap bg-[#341b1b] px-5 py-4 text-base leading-none text-white/50"
      @click="resetWalletState"
    >
      <div class="p-1 text-[#E84142]">
        <Icon class="text-sm" icon="fluent:plug-disconnected-24-filled" />
      </div>
      Disconnect All
    </a>
    <a
      class="larken flex cursor-pointer items-center gap-4 whitespace-nowrap px-5 py-4 text-base leading-none text-white/50 !opacity-100 hover:bg-[#193728] hover:text-white"
      @click="showConnectWalletModal"
    >
      <div class="rounded-full bg-white/5 p-1">
        <Icon class="text-sm text-white" icon="uit:wallet" />
      </div>
      Connect Wallet
      <Icon class="ml-auto text-xl text-[#33FF99]" icon="ic:round-plus" />
    </a>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import type { WalletOption } from '@swapkit/helpers'
import ConnectedWallet from './ConnectedWallet.vue'
import { useConnectWalletModalStore } from '@/store/connectWalletModalStore'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'
import {
  type ConnectChain,
  type Wallet,
  connectChainsRecord,
  walletsRecord,
} from '~/utils/walletconnect'
import type { WalletChain } from '~/wallets/swapkit'

// #region composables
const swapkitWalletStore = useSwapkitWalletStore()
const { resetWalletState } = swapkitWalletStore
const { connectedWallets } = storeToRefs(swapkitWalletStore)

const { showConnectWalletModal } = useConnectWalletModalStore()
// #endregion

const wallets = computed(() =>
  connectedWallets.value.map((wallet) => {
    const connectWallets: { [K in WalletOption]?: Wallet } = walletsRecord
    const connectWallet = connectWallets[wallet.type]
    return {
      ...wallet,
      logo: connectWallet?.logo ?? '/tokens/unknown.png',
      chains: wallet.wallets.map((chainWallet) => {
        const chains: { [K in WalletChain]?: ConnectChain } = connectChainsRecord
        return {
          ...chainWallet,
          logo: chains[chainWallet.chain]?.src ?? '/tokens/unknown.png',
        }
      }),
    }
  }),
)

const emit = defineEmits<{
  close: []
}>()
const emitClose = () => {
  emit('close')
}
const handleClose = (event: MouseEvent) => {
  event.stopImmediatePropagation()
  emitClose()
}
// const connectedChainsAll = computed(() =>
//   Object.values(connectChainsRecord).filter((each) => isChainConnected.value(each.id)),
// )
</script>
